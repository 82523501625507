import { useFeatureFlag } from "configcat-react";

export function Component() {
  
  const { value: homePageTextValue, loading: homePageTextLoading  } = useFeatureFlag("testfeature", "default text");

  return (
    <div>
      {homePageTextLoading ? "Loading..." : homePageTextValue.toString()}
    </div>
  );
}
