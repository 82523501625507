import logo from './logo.svg';
import './App.css';
import { Component } from './Compontent';
import { useState, useEffect } from 'react';
import { getStaticAppSettings } from './staticAppSettings';
import { ConfigCatProvider, createConsoleLogger, LogLevel } from "configcat-react";

function App() {
  const logger = createConsoleLogger(LogLevel.Info); // Set the log level to INFO to track how your feature flags were evaluated. When moving to production, you can remove this line to avoid too detailed logging.
  // call the getStaticAppSettings function and get the foo variable from the process.env object
  // don't load ui until the settings are loaded
  const [settings, setSettings] = useState<{ bar: string; foo: string; env: string } | undefined>(undefined);
  const [configCatSDK, setConfigCatSDK] = useState<string>("");
  useEffect(() => {
    getStaticAppSettings(process.env.NODE_ENV).then((settings) => {
      if (settings.env === "dev") {
        setConfigCatSDK("configcat-proxy/otk-sample-static-website-dev")
      } else {
        setConfigCatSDK("configcat-proxy/otk-sample-static-website-prod")
      }
      setSettings(settings)
    });
  }, []);
  if (settings === undefined) {
    return <div>Loading...</div>
  }


  return (
    <ConfigCatProvider sdkKey={configCatSDK} options={{ logger, baseUrl: "https://flag-proxy.platform-nonprod.imaginelearning.com" }}> 
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo"/>
          <p>
            Hello From Imagine Learning...
          </p>
          <p>
            {settings?.foo}
          </p>
          <Component />
        </header>
      </div>
    </ConfigCatProvider>
  );
}

export default App;
